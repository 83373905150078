<template>
  <v-container>
    <v-row class="text-h2">{{ info.title }}</v-row>
    <v-row class="text-h5 font-weight-light">{{ info.locale }}</v-row>
    <v-row>
      <v-col>{{ info.p1 }} </v-col>
      <v-col>{{ info.p2 }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
import aboutJson from '@/content/about.json';

export default {
  data: function() {
    return {
      info: aboutJson
    };
  }
};
</script>
